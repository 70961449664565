import { RouteComponentProps } from "@reach/router";
import { useMachine } from "@xstate/react";
import anime from "animejs";
import React, { useCallback, useEffect, useRef } from "react";
import { createMachine } from "xstate";
import { Link } from "../../../components/Link/Link";
import { HomePage } from "../../../pages";
import { RatingSystem } from "../components/RatingSystem";
import { ViewProgress } from "../components/ViewProgress";
import { useCookingRecipe } from "../useCookingRecipe";
import { useCookingTranslations } from "../useCookingTranslations";
import { sendCookingEvent } from "../utils";

const machine = createMachine({
  initial: "rating",
  states: {
    rating: {
      on: {
        RATED: "rated",
        ERROR: "error",
      },
    },
    error: {
      on: {
        TRY_AGAIN: "rating",
      },
    },
    rated: {
      type: "final",
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RateStep = (_: React.HTMLProps<HTMLDivElement> & RouteComponentProps) => {
  const handEmojiRef = useRef<HTMLDivElement>(null);
  const title1Ref = useRef<HTMLHeadingElement>(null);
  const ratingRef = useRef<HTMLHeadingElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const authorImgRef = useRef<HTMLImageElement>(null);
  const authorInfo = useRef<HTMLHeadingElement>(null);
  const { id } = useCookingRecipe();

  const [state, send] = useMachine(machine);

  useEffect(() => {
    const baseAnim = {
      opacity: [0, 1],
      duration: 400,
      easing: "easeOutQuad",
    };
    anime
      .timeline()
      .add({
        delay: 400,
        targets: handEmojiRef.current,
        ...baseAnim,
      })
      .add(
        {
          targets: title1Ref.current,
          ...baseAnim,
          translateY: [50, 0],
        },
        "-=200"
      )
      .add(
        {
          targets: ratingRef.current,
          translateY: [50, 0],
          ...baseAnim,
        },
        "-=300"
      )
      .add(
        {
          targets: timeRef.current,
          translateY: [50, 0],
          ...baseAnim,
        },
        "-=300"
      )
      .add(
        {
          targets: authorImgRef.current,
          scale: [0, 1],
          ...baseAnim,
        },
        "-=200"
      )
      .add(
        {
          targets: authorInfo.current,
          ...baseAnim,
        },
        "-=200"
      );
  }, []);

  useEffect(() => {
    sendCookingEvent("cooking_recipe_rate");
  }, []);

  const handleRateSuccess = useCallback(() => send("RATED"), [send]);
  const handleRateError = useCallback(() => send("ERROR"), [send]);
  const handleTryAgain = useCallback(() => send("TRY_AGAIN"), [send]);

  return (
    <div className="flex flex-col items-center w-full bg-turquoise-900">
      <ViewProgress />
      <div className="w-full max-w-md flex flex-col justify-between h-full p-6 z-10 pointer-events-none">
        <div />
        <div className="flex flex-col items-center pointer-events-auto">
          {state.matches("rating") && (
            <RatingState
              handEmojiRef={handEmojiRef}
              title1Ref={title1Ref}
              ratingRef={ratingRef}
              handleRateSuccess={handleRateSuccess}
              handleRateError={handleRateError}
              recipeId={id!}
            />
          )}
          {state.matches("rated") && <RatedState />}
          {state.matches("error") && <ErrorState handleTryAgain={handleTryAgain} />}
        </div>
        <div />
      </div>
    </div>
  );
};

type IStateProps = {
  handEmojiRef: React.RefObject<HTMLDivElement>;
  title1Ref: React.RefObject<HTMLHeadingElement>;
  ratingRef: React.RefObject<HTMLDivElement>;
  handleRateError: () => void;
  handleRateSuccess: () => void;
  recipeId: string;
};

function RatingState({
  handEmojiRef,
  title1Ref,
  ratingRef,
  handleRateError,
  handleRateSuccess,
  recipeId,
}: IStateProps) {
  const tt = useCookingTranslations();
  return (
    <>
      <div ref={handEmojiRef} className="text-6xl w-20 text-center">
        🧑‍🍳
      </div>
      <h1 ref={title1Ref} className="mx-0 opacity-0 my-4 font-medium text-3xl text-violet-500 text-center">
        {tt.greatJob}
      </h1>
      <div ref={ratingRef}>
        <RatingSystem recipeId={recipeId} onRateError={handleRateError} onRateSuccess={handleRateSuccess} />
      </div>
    </>
  );
}

function RatedState() {
  const tt = useCookingTranslations();
  return (
    <>
      <div className="text-6xl w-20 text-center">👏🏽</div>
      <h1 className="mx-0 my-4 font-medium text-3xl text-violet-500 text-center">{tt.thankYou}</h1>
      <div className="flex justify-center mt-8">
        <Link
          to={HomePage}
          className="bg-dark-green text-white rounded-full px-16 py-3 font-medium flex items-center"
        >
          {tt.done}
        </Link>
      </div>
    </>
  );
}

function ErrorState({ handleTryAgain }: { handleTryAgain: () => void }) {
  const tt = useCookingTranslations();
  return (
    <>
      <div className="text-6xl w-20 text-center">🙁</div>
      <h1 className="mx-0 my-4 font-medium text-3xl text-violet-500 text-center">{tt.oops}</h1>
      <h3 className="mx-0 my-4 text-xl font-medium text-white">{tt.somethingWentWrongOnOurEnd}</h3>
      <button
        onClick={handleTryAgain}
        className="bg-dark-green text-white rounded-full px-16 py-3 font-medium flex items-center"
      >
        {tt.tryAgain}
      </button>
    </>
  );
}

import { navigate } from "gatsby";
import { useMatch } from "@reach/router";
import { useCallback, useMemo } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useCookingRecipe } from "./useCookingRecipe";

export function useCookingNavigate() {
  const { totalInstructionsSteps } = useCookingRecipe();
  const [servingSize = ""] = useQueryParam("servingSize", StringParam);

  const matchSlug = useMatch("/cooking/:recipeSlug/*");
  const matchStep = useMatch("/cooking/:recipeSlug/step/:step");
  const matchWelcome = useMatch("/cooking/:recipeSlug");
  const matchRate = useMatch("/cooking/:recipeSlug/rate");

  const recipeSlug = matchSlug?.recipeSlug as string;
  const step = matchStep?.step as string;
  const isWelcome = matchWelcome !== null;
  const isRate = matchRate !== null;

  const stepNum = useMemo(() => parseInt(step, 10), [step]);
  const currentStep = useMemo(() => (isNaN(stepNum) ? -1 : stepNum), [stepNum]);

  const totalSteps = useMemo(
    () => (totalInstructionsSteps ? totalInstructionsSteps : 0),
    [totalInstructionsSteps]
  );

  const isFirstStep = useMemo(() => currentStep === 0, [currentStep]);
  const isLastStep = useMemo(() => totalSteps - 1 === currentStep, [totalSteps, currentStep]);

  const navigateToWelcome = useCallback(() => {
    navigate(`/cooking/${recipeSlug}/welcome?servingSize=${servingSize}`);
  }, [navigate, recipeSlug, servingSize]);

  const navigateToFirstStep = useCallback(() => {
    navigate(`/cooking/${recipeSlug}/step/0?servingSize=${servingSize}`);
  }, [navigate, recipeSlug, servingSize]);

  const navigateToVideo = useCallback(() => {
    navigate(`/cooking/${recipeSlug}/video?servingSize=${servingSize}`);
  }, [navigate, recipeSlug, servingSize]);

  const navigateToRate = useCallback(() => {
    navigate(`/cooking/${recipeSlug}/rate?servingSize=${servingSize}`);
  }, [navigate, recipeSlug, servingSize]);

  const navigateOneStepBack = () => {
    const prevStep = currentStep - 1;
    if (prevStep < 0) {
      navigateToWelcome();
    } else if (isRate) {
      navigate(`/cooking/${recipeSlug}/step/${totalSteps - 1}?servingSize=${servingSize}`);
    } else {
      navigate(`/cooking/${recipeSlug}/step/${prevStep}?servingSize=${servingSize}`);
    }
  };

  const navigateToNextStep = useCallback(() => {
    const nextStep = Math.min(totalSteps - 1 || 0, currentStep + 1);
    navigate(`/cooking/${recipeSlug}/step/${nextStep}?servingSize=${servingSize}`);
  }, [totalSteps, currentStep, navigate, recipeSlug, servingSize]);

  const navigateToLastStep = useCallback(() => {
    navigate(`/cooking/${recipeSlug}/step/${totalSteps - 1}?servingSize=${servingSize}`);
  }, [navigate, recipeSlug, totalSteps, servingSize]);

  const navigateToStep = useCallback(
    (step: number) => {
      navigate(`/cooking/${recipeSlug}/step/${step}?servingSize=${servingSize}`);
    },
    [navigate, recipeSlug, servingSize]
  );

  return {
    navigateToVideo,
    navigateToWelcome,
    navigateToRate,
    navigateToFirstStep,
    navigateOneStepBack,
    navigateToNextStep,
    navigateToLastStep,
    navigateToStep,
    currentStep,
    totalSteps,
    isFirstStep,
    isLastStep,
    isWelcome,
    isRate,
  };
}

import { BaseRecipe } from "../types/BaseRecipe";
import { GetMealplanBySlug_mealplanBySlug } from "../types/GetMealplanBySlug";
import { MealType } from "../types/graphql-global-types";
import { Locale } from "../types/Locale";
import { RecipeDetails } from "../types/MealPlan";
import { ALL_WEEKDAYS, Weekday } from "./date";
import { getMealFieldName } from "./mealplan";
import { mealLabel, weekdayLong } from "./translations";

export const filterRecipeByKeywords = (keywords?: string) => (recipe?: BaseRecipe | null) => {
  if (!keywords) {
    return true;
  }
  if (!recipe) {
    return false;
  }
  return `${recipe.title}${recipe.description}`.toLowerCase().indexOf(keywords.toLowerCase().trim()) >= 0;
};

export const filterRecipeByTags = (tags?: string[]) => (recipe: BaseRecipe) => {
  if (!tags || tags.length === 0) {
    return true;
  }
  return recipe.tags.some((recipeTag) => {
    return !!tags.find((searchTag) => searchTag.toLowerCase() === recipeTag.title.toLowerCase());
  });
};

/**
 * String that shows when a recipe is used (weekdays and meals). Ex.:
 * - Monday lunch, Tuesday breakfast, Friday dinner
 * - Tuesday breakfast, Tuesday lunch, Tuesday dinner
 * - Breakfast Monday - Wednesday
 * @param meals Pairs {weekday, meal}
 */
export const buildMealDaysString = (locale: Locale, meals: Array<{ weekday: Weekday; meal: MealType }>) => {
  const mealKey = meals[0].meal;
  // single meal, several days
  if (meals.length > 1 && meals.every((mealEntry) => mealEntry.meal === mealKey)) {
    const days = meals.map((meal) => meal.weekday);
    return buildMealDaysStringForSingleMeal(locale, mealKey, days);
  }
  return meals
    .sort(compareFnMealsByDayAndType) // sort by day & meal
    .map((meal) => weekdayLong(locale)(meal.weekday.key) + " " + mealLabel(meal.meal))
    .join(", ");
};

/**
 * Sort meals by day & meal type
 */
const compareFnMealsByDayAndType = (
  a: { weekday: Weekday; meal: MealType },
  b: { weekday: Weekday; meal: MealType }
) => {
  // sort by day & meal
  const dayComparison = a.weekday.index - b.weekday.index;
  if (dayComparison === 0) {
    return mealkeyIndex(a.meal) - mealkeyIndex(b.meal);
  }
  return dayComparison;
};

/**
 * String that shows when a recipe is used (single meal, multiple days).
 * @param meal Meal
 * @param weekdays Days
 */
const buildMealDaysStringForSingleMeal = (locale: Locale, meal: MealType, weekdays: Weekday[]) => {
  weekdays.sort((a, b) => a.index - b.index);
  const ranges = [];
  let rangeStart = weekdays[0];
  let rangeEnd = weekdays[0];
  for (let index = 1; index < weekdays.length; index++) {
    const currentDay = weekdays[index];
    if (currentDay.index === rangeEnd.index + 1) {
      // same range
      rangeEnd = currentDay;
    } else {
      // current range interrupted, save and start a new one
      ranges.push({ start: rangeStart, end: rangeEnd });
      rangeStart = currentDay;
      rangeEnd = currentDay;
    }
  }
  ranges.push({ start: rangeStart, end: rangeEnd });
  return mealLabel(meal, true) + " " + ranges.map(buildStringForWeekdayRange(locale)).join(", ");
};

/**
 * String that represents a range of consecutive weekdays. Ex.:
 * - Monday
 * - Monday, Tuesday
 * - Monday - Wednesday
 * @param range Start and end day of the range
 */
const buildStringForWeekdayRange = (locale: Locale) => (range: { start: Weekday; end: Weekday }) => {
  const start = weekdayLong(locale)(range.start.key);
  const end = weekdayLong(locale)(range.end.key);

  // single day
  if (range.start.index === range.end.index) {
    return start;
  }
  // 2 consecutive days
  if (range.start.index + 1 === range.end.index) {
    return start + ", " + end;
  }
  // > 2 consecutive days
  return start + " - " + end;
};

/**
 * Returns an index for a mealkey for sorting purposes.
 * @param meal Meal key
 */
const mealkeyIndex = (meal: MealType) => {
  return meal === MealType.BREAKFAST ? 0 : meal === MealType.LUNCH ? 1 : 2;
};

interface RecipeForWeek {
  recipe: RecipeDetails;
  meals: Array<{
    weekday: Weekday;
    meal: MealType;
  }>;
}

export const MEALS: MealType[] = [MealType.BREAKFAST, MealType.LUNCH, MealType.DINNER];

/**
 * Build a list of unique recipes decorated with a list of meals where the recipe
 * is used.
 * @param mealplan
 */
export const getRecipeMeals = (
  mealplan: GetMealplanBySlug_mealplanBySlug,
  isReadOnly = false
): RecipeForWeek[] => {
  const recipesForWeek: RecipeForWeek[] = [];
  ALL_WEEKDAYS.forEach((weekday) => {
    const mealPlanDay = mealplan.schedule.find((day) => day && day.name === weekday.key) || undefined;
    if (mealPlanDay) {
      MEALS.forEach((mealKey) => {
        const mealFieldName = getMealFieldName(mealKey);
        const meal = mealPlanDay[mealFieldName];
        // Read only meal plans doesn't take `active` (skipped) field into account.
        if (isReadOnly || meal.active) {
          const mealRecipes = meal.recipesDetails.filter(Boolean) as RecipeDetails[];
          mealRecipes.forEach((recipe) => {
            const repeatingRecipe = recipesForWeek.find(
              (recipeForWeek) => recipeForWeek.recipe.id === recipe.id
            );
            if (repeatingRecipe) {
              repeatingRecipe.meals.push({
                weekday,
                meal: mealKey,
              });
            } else {
              recipesForWeek.push({
                recipe,
                meals: [
                  {
                    weekday,
                    meal: mealKey,
                  },
                ],
              });
            }
          });
        }
      });
    }
  });
  return recipesForWeek;
};

/**
 * Check if a list of meals represent a "dinner + lunch on the following day" combo.
 * @param meals List of meals
 */
export const isDinnerLunchMealCombo = (meals: Array<{ weekday: Weekday; meal: MealType }>) => {
  if (meals.length !== 2) return false;
  const [mealA, mealB] = [...meals].sort(compareFnMealsByDayAndType);

  return (
    mealA.meal === MealType.DINNER &&
    mealB.meal === MealType.LUNCH &&
    mealA.weekday.index === mealB.weekday.index - 1
  );
};

export const recipeTimeHasTotal = (active?: number, total?: number) =>
  typeof active !== "undefined" && typeof total !== "undefined" && active !== 0 && total !== 0;

export const getRecipeTime = (time?: { active: number; total: number; preparation: number; cook: number }) => {
  if (typeof time === "undefined") return "";
  const { active, total, preparation, cook } = time;
  const withoutTotalTime = cook + preparation;
  return `${recipeTimeHasTotal(active, total) ? total : withoutTotalTime}`;
};

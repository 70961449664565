import { RouteComponentProps } from "@reach/router";
import anime, { AnimeTimelineInstance } from "animejs";
import { Interweave } from "interweave";
import React, { useEffect, useMemo, useRef } from "react";
import { buildMatcher as buildIngredientMatcher } from "../components/IngredientMatcher";
import { IngredientPreview } from "../components/IngredientsPreview";
import { ViewProgress } from "../components/ViewProgress";
import { useCookingNavigate } from "../useCookingNavigate";
import { useCookingRecipe } from "../useCookingRecipe";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CookingStep = (_: React.HTMLProps<HTMLDivElement> & RouteComponentProps) => {
  const animRef = useRef<AnimeTimelineInstance>();
  const stepNumRef = useRef<HTMLHeadingElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const stepInstructionRef = useRef<HTMLParagraphElement>(null);

  const { currentStep } = useCookingNavigate();
  const { instructions, ingredientsList } = useCookingRecipe();

  const stepInstruction = useMemo(() => instructions?.[currentStep], [instructions, currentStep]);

  useEffect(() => {
    animRef.current = anime
      .timeline()
      .add({
        delay: 300,
        targets: stepNumRef.current,
        translateY: [50, 0],
        opacity: [0, 1],
        duration: 400,
        easing: "easeOutQuad",
      })
      .add(
        {
          targets: titleRef.current,
          translateX: [50, 0],
          opacity: [0, 1],
          duration: 400,
          easing: "easeOutQuad",
        },
        "-=300"
      )
      .add(
        {
          targets: stepInstructionRef.current,
          translateX: [50, 0],
          opacity: [0, 1],
          duration: 400,
          easing: "easeOutQuad",
        },
        "-=300"
      );

    return () => animRef.current?.restart();
  }, [currentStep]);

  const matchers = useMemo(
    () => ingredientsList?.map((i) => buildIngredientMatcher({ ingredient: i })),
    [ingredientsList]
  );

  return (
    <div className="flex flex-col items-center w-full bg-light-sand overflow-hidden select-none">
      <ViewProgress />
      <IngredientPreview />
      <div className="p-6 max-w-3xl flex flex-col h-full justify-center">
        <h2
          ref={stepNumRef}
          className="bg-green my-6 rounded-full font-medium text-white w-9 h-9 flex justify-center items-center text-2xl m-0 p-0"
        >
          {currentStep + 1}
        </h2>
        <div ref={stepInstructionRef} className="opacity-0 font-medium leading-tight text-3xl text-blue-600">
          <Interweave noWrap content={stepInstruction} matchers={matchers} />
        </div>
      </div>
    </div>
  );
};

import differenceInDays from "date-fns/fp/differenceInDays";
import parseISO from "date-fns/fp/parseISO";
import subDays from "date-fns/fp/subDays";
import { useCallback, useMemo } from "react";
import { useStateWithLocalStorage } from "../useStateWithLocalStorage/useStateWithLocalStorage";

/**
 * It saves a value in the local storage
 * and returns if the value is expired
 */
export function useStoreValueWithDaysLimit(key: string, limitInDays = 7): [boolean, VoidFunction, string] {
  const [value, setValue] = useStateWithLocalStorage<string>(
    key,
    subDays(limitInDays, new Date()).toISOString()
  );

  const days = useMemo(() => differenceInDays(parseISO(value), new Date()), [value]);
  const isExpired = useMemo(() => days >= limitInDays, [days, limitInDays]);

  const reset = useCallback(() => {
    setValue(new Date().toISOString());
  }, [setValue]);

  return [isExpired, reset, value];
}

import React from "react";

/**
 * Screen sizes. They are based on the available width.
 */
export enum ScreenSize {
  MOBILE = 0,
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export const ascendentScreenSizes = Object.values(ScreenSize)
  .filter((value): value is ScreenSize => typeof value === "number")
  .sort((a, b) => a - b);

const descendentScreenSizes = Object.values(ScreenSize)
  .filter((value): value is ScreenSize => typeof value === "number")
  .sort((a, b) => b - a);

/**
 * Returns the current screen category. During SSR the default value is `mobile`.
 */
export function useScreenSize(): ScreenSize {
  const [screenSize, setScreenSize] = React.useState(getInitialScreenSize);

  React.useLayoutEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    function listener() {
      const width = window.innerWidth;
      const screenSize = getScreenSizeForWidth(width);
      setScreenSize(screenSize);
    }

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [setScreenSize]);

  return screenSize;
}

function getInitialScreenSize(): ScreenSize {
  const width = typeof window !== "undefined" ? window.innerWidth : 0;
  return getScreenSizeForWidth(width);
}

function getScreenSizeForWidth(width: number): ScreenSize {
  for (const screenSize of descendentScreenSizes) {
    if (width >= screenSize) {
      return screenSize;
    }
  }

  return ScreenSize.MOBILE;
}

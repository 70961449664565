import { sendEvent } from "../../utils/gtm";

export const normalizeName = (
  value: string,
  unit: string,
  beforeName: string,
  afterName: string,
  plural: string,
  singular: string
) => {
  const quantity = `${value} ${unit}`;
  const hasNoQuantity = quantity.trim() === "";

  return hasNoQuantity ? plural ?? singular : `${quantity} ${beforeName}${plural ?? singular}${afterName}`;
};

export const sendCookingEvent = (action: string, payload?: object) =>
  sendEvent({
    eventName: "cooking_mode",
    action,
    payload,
  });

import React from "react";

export function ClockIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M7.86046 1.47771C4.64046 1.47771 2.03296 4.09104 2.03296 7.31104C2.03296 10.531 4.64046 13.1444 7.86046 13.1444C11.0863 13.1444 13.6996 10.531 13.6996 7.31104C13.6996 4.09104 11.0863 1.47771 7.86046 1.47771ZM7.86629 11.9777C5.28796 11.9777 3.19963 9.88937 3.19963 7.31104C3.19963 4.73271 5.28796 2.64437 7.86629 2.64437C10.4446 2.64437 12.533 4.73271 12.533 7.31104C12.533 9.88937 10.4446 11.9777 7.86629 11.9777Z"
          className="fill-current text-current"
        />
        <path
          d="M8.15796 4.39436H7.28296V7.89436L10.3455 9.73186L10.783 9.01436L8.15796 7.45686V4.39436Z"
          className="fill-current text-current"
        />
      </g>
    </svg>
  );
}

import React, { useCallback, useMemo } from "react";
import { useCookingNavigate } from "../useCookingNavigate";

const addWelcomeView = (steps: number) => steps + 1;
const addRateView = (steps: number) => steps + 1;

export function ViewProgress() {
  const { isWelcome, isRate, currentStep, totalSteps, navigateToStep, navigateToWelcome, navigateToRate } =
    useCookingNavigate();

  const viewNum = useMemo(
    () => (isWelcome ? 0 : isRate ? totalSteps + 1 : currentStep + 1),
    [currentStep, isRate, isWelcome, totalSteps]
  );

  const totalViews = useMemo(() => addWelcomeView(addRateView(totalSteps)), [totalSteps]);
  const views = useMemo(() => new Array(totalViews).fill(null), [totalViews]);

  const navigateToView = useCallback(
    (viewNum: number) => () => {
      if (viewNum === 0) {
        navigateToWelcome();
      } else if (viewNum === totalViews - 1) {
        navigateToRate();
      } else {
        navigateToStep(viewNum - 1);
      }
    },
    [navigateToStep, navigateToRate, navigateToWelcome, totalViews]
  );

  const isFirstView = useMemo(() => viewNum === 0, [viewNum]);
  const isLastView = useMemo(() => viewNum === totalViews - 1, [totalViews, viewNum]);

  return (
    <>
      {!isFirstView && (
        <a onClick={navigateToView(viewNum - 1)} className="cursor-pointer min-h-screen w-1/2 fixed left-0" />
      )}
      {!isLastView && (
        <a onClick={navigateToView(viewNum + 1)} className="cursor-pointer min-h-screen w-1/2 fixed left-1/2" />
      )}
      <div className="flex gap-2 justify-between w-full max-w-3xl p-4">
        {views.map((_, idx) => {
          const opacity = idx <= viewNum ? "" : "opacity-50";
          return (
            <div
              key={idx}
              data-step={idx}
              className={`bg-green w-full h-1.5 rounded-md ${opacity}`}
              onClick={navigateToView(idx)}
            />
          );
        })}
      </div>
    </>
  );
}

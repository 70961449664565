import { ascendentScreenSizes } from "./screen-sizes";

export interface AspectRatio {
  width: number;
  height: number;
}

function getImageSrc(path: string, { width, aspectRatio, pixelRatio }: GetImageSrcOptions = {}): string {
  const w = width ? `&w=${width}` : "";
  const ar = aspectRatio ? `&ar=${aspectRatio.width}:${aspectRatio.height}` : "";
  const dpr = pixelRatio ? `&dpr=${pixelRatio}` : "";
  return `https://i.dietdoctor.com${path}?auto=compress%2Cformat&fit=crop${w}${ar}${dpr}`;
}

interface GetImageSrcOptions {
  width?: number;
  fit?: string;
  aspectRatio?: AspectRatio;
  pixelRatio?: number;
}

export function getImageSrcSet(path: string, { aspectRatio, width }: GetImageSrcSetOptions = {}) {
  let srcs: string[];

  if (width) {
    const widthInPixels = convertRemToPixels(parseInt(width, 10));
    srcs = imagePixelRatios.map((pixelRatio) => {
      const src = getImageSrc(path, { width: widthInPixels, aspectRatio, pixelRatio });
      return `${src} ${pixelRatio}x`;
    });
  } else {
    srcs = imageWidths.map((width) => {
      const src = getImageSrc(path, { width, aspectRatio });
      return `${src} ${width}w`;
    });
  }

  const src = srcs[0].substring(0, srcs[0].lastIndexOf(" "));

  return {
    src,
    srcSet: srcs.join(", "),
    sizes: width,
  };
}

type GetImageSrcSetOptions = {
  aspectRatio?: AspectRatio;
  width?: ImageWidth;
};

function convertRemToPixels(value: number): number {
  const rem =
    typeof window !== "undefined"
      ? parseInt(window.getComputedStyle(document.documentElement).fontSize, 10)
      : 16;

  return value * rem;
}

const imageWidths = ascendentScreenSizes.filter((screenSize) => screenSize > 0);
const imagePixelRatios = [1, 2, 3];

type ImageWidth = `${number}rem`;

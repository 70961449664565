import gql from "graphql-tag";

export const GET_RECIPE_BY_SLUG = gql`
  query RecipeQuery($slug: String!) {
    recipeBySlug(slug: $slug) {
      id
      videos {
        id
        type
      }
      difficulty {
        rating
      }
      authors {
        main {
          fullName
          type
          description
        }
        contributors {
          fullName
          role
          image {
            defaultImage {
              path
            }
          }
        }
      }
      title
      description
      rating
      isHighProtein
      slug
      servings {
        default
      }
      strictness {
        rating
      }
      images {
        vtImage {
          path
        }
      }
      time {
        preparation
        cook
        total
        active
      }
      instructionSections {
        title
        header {
          text
        }
        footer {
          text
        }
        steps
      }
      ingredientSections {
        title
        ingredients {
          id
          ingredient {
            id
            titles {
              singular
              plural
            }
          }
          optional
          beforeName
          afterName
          values {
            us {
              dualValue {
                unit
                value
              }
              optionalValues {
                unit
                value
              }
              servingSize
              unit
              value
            }
            metric {
              dualValue {
                unit
                value
              }
              optionalValues {
                unit
                value
              }
              servingSize
              unit
              value
            }
            sv {
              dualValue {
                unit
                value
              }
              optionalValues {
                unit
                value
              }
              servingSize
              unit
              value
            }
          }
          alternateIngredients {
            id
            ingredient {
              id
              titles {
                singular
                plural
              }
            }
            beforeName
            afterName
          }
          additionalIngredients {
            id
            ingredient {
              id
              titles {
                singular
                plural
              }
            }
            beforeName
            afterName
          }
        }
      }
    }
  }
`;

import * as React from "react";
import { useCallback } from "react";
import { useStoreValueWithDaysLimit } from "../../../hooks/useStoreValueWithDaysLimit/useStoreValueWithDaysLimit";
import { useCookingTranslations } from "../useCookingTranslations";
import { sendCookingEvent } from "../utils";

export function OverlayNavigationInstructions() {
  const tt = useCookingTranslations();
  const [isExpired, reset] = useStoreValueWithDaysLimit("dd/cooking/nav-tutorial", 1);
  const handleClick = useCallback(() => {
    sendCookingEvent("navigation_tutorial_done");
    reset();
  }, [reset]);

  if (!isExpired) return null;

  return (
    <div className="fixed z-30 flex w-full h-full bg-[#000]/80">
      <div className="absolute left-1/2 border-r border-r-white/60 h-full border-dashed"></div>
      <div className="flex flex-col w-full justify-center items-center text-white font-medium">
        <div className="text-2xl">{tt.tapToNavigate}</div>
        <div className="flex justify-between w-full text-xl">
          <div className="w-1/2 flex justify-center my-16">{tt.back}</div>
          <div className="w-1/2 flex justify-center my-16">{tt.next}</div>
        </div>
        <button
          onClick={handleClick}
          className="bg-[#000] z-10 px-12 py-2 rounded-full border-2 border-white text-lg"
        >
          {tt.gotIt}
        </button>
      </div>
    </div>
  );
}

import { useContext, useEffect, useMemo, useState } from "react";
import { getImageSrcSet } from "../../utils/images";
import { getSrc, getSrcWithWidth } from "../../utils/image";
import { IngredientUnit } from "../../utils/units";
import { CookingStateContext } from "./CookingStateProvider";
import { ICookingCheckList, ICookingIngredients, ICookingIngredientValue } from "./types";
import { normalizeName } from "./utils";

const getByServingSize = (
  values: ICookingIngredientValue[],
  servingSize: number
): ICookingIngredientValue | undefined => values.find((v) => v.servingSize === servingSize);

const titleOrIngredientsTitle = (title: string) => (title ? title : "Ingredients");

const getCheckList = (
  unit: IngredientUnit,
  servingSize: number,
  allIngredients: ICookingIngredients[] | undefined
) =>
  allIngredients?.map((list) => ({
    title: titleOrIngredientsTitle(list.title),
    ingredients: list.ingredients.map((ingredient) => ({
      title: `${normalizeName(
        getByServingSize(ingredient.values[unit], servingSize)?.value ?? "",
        getByServingSize(ingredient.values[unit], servingSize)?.unit ?? "",
        "",
        "",
        ingredient.ingredient.titles.plural,
        ingredient.ingredient.titles.singular
      )}`,
      checked: false,
    })),
  }));

export function useCookingRecipe() {
  const { recipe, unit, servingSize } = useContext(CookingStateContext);

  const imagePath = useMemo(
    () => getSrc(recipe?.images.vtImage.path ?? "", { width: 48, height: 48 }),
    [recipe]
  );

  const bigImagePath = useMemo(
    () => getSrcWithWidth(recipe?.images.vtImage.path ?? "", { width: 480 }),
    [recipe]
  );

  const id = useMemo(() => recipe?.id, [recipe]);
  const title = useMemo(() => recipe?.title, [recipe]);
  const description = useMemo(() => recipe?.description, [recipe]);
  const isHighProtein = useMemo(() => recipe?.isHighProtein, [recipe]);
  const rating = useMemo(() => recipe?.rating, [recipe]);
  const time = useMemo(() => recipe?.time, [recipe]);
  const instructions = useMemo(() => recipe?.instructionSections[0]?.steps, [recipe]);
  const allIngredients = useMemo(() => recipe?.ingredientSections, [recipe]);
  const difficulty = useMemo(() => recipe?.difficulty, [recipe]);
  const authors = useMemo(() => recipe?.authors, [recipe]);
  const servings = useMemo(
    () => servingSize ?? recipe?.servings.default ?? 1, //FIXME: Could be problematic if we don't have a default servings size
    [recipe?.servings.default, servingSize]
  );
  const videos = useMemo(() => recipe?.videos, [recipe]);
  const video = useMemo(
    () => videos?.find((x) => x.type === "YOUTUBE") ?? videos?.find((x) => x.type === "VIMEO") ?? null,
    [videos]
  );

  const totalInstructionsSteps = useMemo(() => instructions?.length, [instructions]);

  const recipeAuthorCreator = useMemo(() => authors?.contributors.find((c) => c.role === "Recipe"), [authors]);

  const recipeAuthorCreatorImage = useMemo(() => {
    const path = recipeAuthorCreator?.image.defaultImage.path;
    return path ? getImageSrcSet(path) : null;
  }, [recipeAuthorCreator]);

  const ingredientsList = useMemo(
    () =>
      allIngredients?.flatMap((i) =>
        i.ingredients.flatMap((i) => ({
          singular: i.ingredient.titles.singular,
          plural: i.ingredient.titles.plural,
          unit: getByServingSize(i.values[unit], servings)?.unit ?? "",
          value: getByServingSize(i.values[unit], servings)?.value ?? "",
          beforeName: i.beforeName,
          afterName: i.afterName,
        }))
      ),
    [allIngredients, servings, unit]
  );

  const [checkList, setCheckList] = useState<ICookingCheckList>(getCheckList(unit, servings, allIngredients));

  useEffect(() => {
    setCheckList(getCheckList(unit, servings, allIngredients));
  }, [allIngredients, servings, unit]);

  return {
    video,
    checkList,
    setCheckList,
    id,
    servings,
    difficulty,
    title,
    description,
    isHighProtein,
    rating,
    time,
    instructions,
    imagePath,
    bigImagePath,
    totalInstructionsSteps,
    allIngredients,
    recipeAuthorCreator,
    recipeAuthorCreatorImage,
    ingredientsList,
  };
}

import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import React, { useEffect, useMemo, useRef } from "react";
import { getRecipeTime } from "../../../utils/recipe";
import { ClockIcon } from "../components/ClockIcon";
import { OverlayNavigationInstructions } from "../components/OverlayNavigationInstructions";
import { ViewProgress } from "../components/ViewProgress";
import { useCookingRecipe } from "../useCookingRecipe";
import { useCookingTranslations } from "../useCookingTranslations";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const WelcomeStep = (props: React.HTMLProps<HTMLDivElement> & RouteComponentProps) => {
  const handEmojiRef = useRef<HTMLDivElement>(null);
  const title1Ref = useRef<HTMLHeadingElement>(null);
  const title2Ref = useRef<HTMLHeadingElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const authorImgRef = useRef<HTMLImageElement>(null);
  const authorInfo = useRef<HTMLHeadingElement>(null);
  const tt = useCookingTranslations();

  const { id, title, difficulty, servings, time, recipeAuthorCreator, recipeAuthorCreatorImage } =
    useCookingRecipe();
  const totalTime = useMemo(() => getRecipeTime(time), [time]);

  useEffect(() => {
    if (typeof title !== "undefined") {
      anime
        .timeline()
        .add({
          delay: 400,
          targets: handEmojiRef.current,
          opacity: ["0", "1"],
          duration: 400,
          easing: "easeInOutQuad",
        })
        .add(
          {
            targets: title1Ref.current,
            opacity: ["0", "1"],
            translateY: [50, 0],
            duration: 400,
            easing: "easeOutQuad",
          },
          "-=200"
        )
        .add(
          {
            targets: title2Ref.current,
            opacity: ["0", "1"],
            translateY: [50, 0],
            duration: 400,
            easing: "easeOutQuad",
          },
          "-=300"
        )
        .add(
          {
            targets: timeRef.current,
            opacity: ["0", "1"],
            translateY: [50, 0],
            duration: 400,
            easing: "easeOutQuad",
          },
          "-=300"
        )
        .add(
          {
            targets: authorImgRef.current,
            opacity: ["0", "1"],
            scale: [0, 1],
            duration: 300,
            easing: "easeOutQuad",
          },
          "-=200"
        )
        .add(
          {
            targets: authorInfo.current,
            opacity: ["0", "1"],
            duration: 300,
            easing: "easeOutQuad",
          },
          "-=200"
        );
    }
  }, [title]);

  return (
    <div className="flex flex-col items-center w-full bg-turquoise-900">
      <OverlayNavigationInstructions />
      <ViewProgress />
      <div className="w-full max-w-md flex flex-col justify-between h-full p-6 pointer-events-none z-10">
        <div></div>
        <div className="flex flex-col">
          <div data-recipe-id={id} ref={handEmojiRef} className="text-6xl w-20 origin-[bottom_30%] text-center">
            👋
          </div>
          <h1 ref={title1Ref} className="mx-0 opacity-0 my-4 font-medium text-3xl text-white">
            {tt.letsGetCooking}
          </h1>
          <h3 ref={title2Ref} className="mx-0 opacity-0 my-4 text-xl font-medium text-white">
            {title}
          </h3>
          <div ref={timeRef} className="flex text-cyan-700 items-center font-normal text-base">
            <ClockIcon />
            <span className="px-2">{totalTime} min</span>
            <span>|</span>
            <span className="px-2 capitalize">{difficulty?.rating}</span>
            <span>|</span>
            <span className="px-2">
              {servings} {tt.servings}
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          {recipeAuthorCreator && (
            <div className="flex flex-col w-36">
              {recipeAuthorCreatorImage && (
                <div className="mt-4 w-36 h-36 rounded-full overflow-hidden flex justify-center">
                  <img ref={authorImgRef} className="h-36" alt="" src={recipeAuthorCreatorImage.src} />
                </div>
              )}
              <h4 ref={authorInfo} className="text-white opacity-80 italic text-lg text-center mt-2">
                {recipeAuthorCreator?.fullName}
                <br />
                {tt.creator}
              </h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

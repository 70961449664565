import { ElementProps, MatcherInterface } from "interweave";
import * as React from "react";
import { useMemo } from "react";
import { normalizeName } from "../utils";

type MatchedIngredient = {
  singular: string;
  plural: string;
  value: string;
  unit: string;
  beforeName: string;
  afterName: string;
};

type Props = { ingredient: MatchedIngredient };

export const buildMatcher = ({ ingredient }: { ingredient: MatchedIngredient }): MatcherInterface<Props> => ({
  inverseName: "noIngredient",
  propName: "ingredient",
  match(str: string) {
    const result =
      new RegExp(`\\b(${ingredient.plural})\\b`, "gi").exec(str) ??
      new RegExp(`\\b(${ingredient.singular})\\b`, "gi").exec(str);

    if (!result) {
      return null;
    }

    return {
      ingredient: ingredient,
      index: result.index!,
      length: result[0].length,
      match: result[0],
      valid: true,
    };
  },

  createElement(children, props) {
    return React.createElement(IngredientMatcherComponent, { ...props, tagName: "span" }, children);
  },

  asTag() {
    return "span";
  },
});

export function IngredientMatcherComponent(props: ElementProps & Props) {
  const { children, ingredient } = props;
  const { value, unit, beforeName, afterName, singular, plural } = ingredient;

  const extras = useMemo(
    () => normalizeName(value, unit, beforeName, afterName, plural, singular),
    [afterName, beforeName, plural, singular, unit, value]
  );

  return extras !== "" ? (
    <>
      <span className="text-turquoise-600">{children}</span>
      <span className="text-base italic pl-2">({extras})</span>
    </>
  ) : (
    <span>{children}</span>
  );
}

import { ApolloClient, NormalizedCacheObject, useApolloClient } from "@apollo/client";
import { useMachine, useSelector } from "@xstate/react";
import React, { createContext, useCallback, useMemo } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { State } from "xstate";
import { useMeasurementUnit } from "../../hooks/useMeasurementUnit/useMeasurementUnit";
import { IngredientUnit } from "../../utils/units";
import { buildMachine } from "./machine";
import { CookingMachineContext, ICookingRecipe } from "./types";

export const CookingStateContext = createContext<{
  getRecipeBySlug?: (recipeSlug: string) => void;
  recipe?: ICookingRecipe | null;
  unit: IngredientUnit;
  servingSize?: number | null | undefined;
}>({
  unit: IngredientUnit.METRIC,
});

const recipeSelector = (state: State<CookingMachineContext>) => state.context.recipe;

export const CookingStateProvider: React.FunctionComponent = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const mConfig = useMemo(() => buildMachine(client), [client]);
  const [, send, interpreter] = useMachine(mConfig);
  const { ingredientUnit } = useMeasurementUnit();
  const [servingSize] = useQueryParam("servingSize", StringParam);

  const recipe = useSelector(interpreter, recipeSelector);

  const getRecipeBySlug = useCallback(
    (recipeSlug: string) => {
      send({ type: "GET_RECIPE_BY_SLUG", recipeSlug });
    },
    [send]
  );

  return (
    <CookingStateContext.Provider
      value={{
        recipe,
        getRecipeBySlug,
        servingSize: servingSize ? Number(servingSize) : undefined,
        unit: ingredientUnit,
      }}
    >
      {children}
    </CookingStateContext.Provider>
  );
};

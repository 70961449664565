import { useContext, useEffect } from "react";
import { useNavigate } from "../../components/Link/Link";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { LoggedInHomePage, LoginPage } from "../../pages";

export function useRedirectToLogin(forcePremium = true) {
  const { loading, premium, user } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user && forcePremium && !premium) {
      navigate({ to: LoggedInHomePage });
    }
    if (!loading && !user) {
      navigate({ to: LoginPage, query: { redirectTo: window.location.href } });
    }
  }, [forcePremium, loading, navigate, premium, user]);

  return { loading };
}

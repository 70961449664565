import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useUser } from "../../hooks/useUser/useUser";
import { LoginPage } from "../../pages";
import { isBrowser } from "../../utils/ssr";
import { useNavigate } from "../Link/Link";

type RouteProps = RouteComponentProps & {
  component: React.ComponentType<RouteComponentProps>;
  redirectionPath?: string;
};

const RestrictedRoute = ({ component: Component, redirectionPath, ...rest }: RouteProps) => {
  const { loggedIn, loading } = useUser();
  const navigate = useNavigate();
  const windowOrigin = isBrowser() ? window.location.origin : process.env.GATSBY_WEBSITE_URL;
  const baseUrl = windowOrigin;

  if (!loggedIn && !loading) {
    navigate({ to: LoginPage, query: { redirectTo: `${baseUrl}${redirectionPath ?? ""}` } });
    return null;
  }
  return <Component {...rest} />;
};

// ts-prune-ignore-next
export default RestrictedRoute;

import { Router } from "@reach/router";
import React, { useMemo } from "react";
import RestrictedRoute from "../../components/Route/RestrictedRoute";
import { SEO } from "../../components/SEO/SEO";
import { useRedirectToLogin } from "../../hooks/useRedirectToLogin/useRedirectToLogin";
import { isBrowser } from "../../utils/ssr";
import { CookingRecipe } from "./CookingRecipe";
import { CookingStateProvider } from "./CookingStateProvider";
import { CookingStep } from "./steps/CookingStep";
import { LoadingStep } from "./steps/LoadingStep";
import { RateStep } from "./steps/RateStep";
import { VideoStep } from "./steps/VideoStep";
import { WelcomeStep } from "./steps/WelcomeStep";
import { useCookingTranslations } from "./useCookingTranslations";

const CookingPage = ({ pageContext }: { pageContext: { prefix: string } }) => {
  const path = pageContext.prefix;

  const cookingUrl = useMemo(() => (isBrowser() ? window.document.location.pathname : undefined), []);

  const { loading } = useRedirectToLogin(true);

  if (loading) return null;

  return (
    <>
      <CookingStateProvider>
        <Router basepath={path} className="flex w-full min-h-screen">
          <CookingRecipe path=":recipeSlug">
            <RestrictedRoute redirectionPath={cookingUrl} component={LoadingStep} path="/" />
            <RestrictedRoute redirectionPath={cookingUrl} component={WelcomeStep} path="welcome" />
            <RestrictedRoute redirectionPath={cookingUrl} component={CookingStep} path="step/:step" />
            <RestrictedRoute redirectionPath={cookingUrl} component={RateStep} path="rate" />
            <RestrictedRoute redirectionPath={cookingUrl} component={VideoStep} path="video" />
          </CookingRecipe>
        </Router>
      </CookingStateProvider>
    </>
  );
};

export default CookingPage;

export const Head = ({ location }: { location: { pathname: string } }) => {
  const tt = useCookingTranslations();
  return <SEO title={tt.cookingPage} pathName={location.pathname} />;
};

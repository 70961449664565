import { MdUnfoldMore } from "@react-icons/all-files/md/MdUnfoldMore";
import anime from "animejs";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ICookingCheckList, ICookingCheckListItem } from "../types";
import { useCookingNavigate } from "../useCookingNavigate";
import { useCookingRecipe } from "../useCookingRecipe";
import { useCookingTranslations } from "../useCookingTranslations";
import { sendCookingEvent } from "../utils";
import { PlayVideoIcon } from "./PlayVideoIcon";

export function IngredientPreview() {
  const tt = useCookingTranslations();
  const { navigateToVideo } = useCookingNavigate();
  const imgRef = useRef<HTMLImageElement>(null);
  const { checkList, setCheckList, imagePath, bigImagePath, title, video } = useCookingRecipe();
  const [open, setOpen] = useState(false);

  const onClickOnPlayVideo = useCallback(() => {
    navigateToVideo();
  }, [navigateToVideo]);

  const handleOpen = useCallback(() => {
    const newValue = !open;
    if (newValue) {
      sendCookingEvent("ingredients_preview_open");
    } else {
      sendCookingEvent("ingredients_preview_close");
    }
    setOpen(newValue);
  }, [open]);

  const totalIngredients = useMemo(() => checkList?.flatMap((x) => x.ingredients.flat()).length, [checkList]);

  useEffect(() => {
    anime.timeline().add({
      delay: 400,
      targets: imgRef.current,
      opacity: ["0", "1"],
      duration: 400,
      easing: "easeInOutQuad",
    });
  }, []);

  return (
    <>
      {open && <div className="bg-[#000]/0 fixed top-0 left-0 w-full h-full" onClick={handleOpen}></div>}
      <div
        className={`${
          open ? "shadow-sm" : ""
        } flex flex-col relative w-10/12 mx-4 self-start z-10 max-w-md md:self-center`}
      >
        <div
          className="bg-white h-12 rounded-lg overflow-hidden flex w-full cursor-pointer"
          onClick={handleOpen}
        >
          <div className="relative flex justify-center items-center w-12 h-12 grow-0 shrink-0">
            {video && <PlayVideoIcon className="absolute w-5 h-5 z-10" onClick={onClickOnPlayVideo} />}
            <img ref={imgRef} className="absolute top-0 left-0 w-12 z-0" alt={title} src={imagePath} />
          </div>
          <div className="flex flex-col justify-center text-xs px-4 overflow-hidden grow">
            <h3 className="font-normal truncate m-0 h-4 text-base">{title}</h3>
            <h3 className="font-medium m-0 h-4 text-base">
              {totalIngredients} {tt.ingredients}
            </h3>
          </div>
          <div className="w-6 shrink-0 flex justify-center items-center">
            <MdUnfoldMore />
          </div>
        </div>
        {open && (
          <div className="bg-white h-96 md:h-[48rem] overflow-y-auto rounded-lg mt-14 absolute p-4 w-full shadow-lg">
            {checkList?.map((list, idx0) => (
              <div key={idx0}>
                <h4 className="m-0 p-0 font-medium text-lg mb-2">{list.title}</h4>
                <ul className="list-none m-0 p-0 mb-6">
                  {list.ingredients.map((ingredient, idx1) => (
                    <li key={idx1} className="text-base flex items-center py-1">
                      <div className="w-6 mr-1.5 grow-0">
                        <Checkbox ingredient={ingredient} checkList={checkList} setCheckList={setCheckList} />
                      </div>
                      <div className={`grow ${ingredient.checked ? "line-through opacity-50" : ""}`}>
                        {ingredient.title}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div>
              <img className="w-full" alt={title} src={bigImagePath} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function Checkbox({
  checkList,
  ingredient,
  setCheckList,
}: {
  checkList: ICookingCheckList;
  ingredient: ICookingCheckListItem;
  setCheckList: (list: ICookingCheckList) => void;
}) {
  const handleCheck = useCallback(() => {
    const newValue = !ingredient.checked;

    if (newValue) {
      sendCookingEvent("ingredients_check");
    } else {
      sendCookingEvent("ingredients_uncheck");
    }

    const updated = checkList?.map((list) => ({
      ...list,
      ingredients: list.ingredients.map((i) => ({
        ...i,
        checked: i === ingredient ? newValue : i.checked,
      })),
    }));

    setCheckList(updated);
  }, [checkList, ingredient, setCheckList]);

  return (
    <span
      onClick={handleCheck}
      className={`cursor-pointer shrink-0 select-none overflow-hidden relative rounded-sm border-2 w-[22px] h-[22px] flex justify-center items-center ${
        ingredient.checked ? "bg-green border-green" : "border-light-grey"
      }`}
    >
      <svg
        className="absolute left-0.5"
        width="13"
        height="11"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.348 2.91538L6.21119 11.1308C5.93692 11.4077 5.57122 11.5 5.20552 11.5C4.83982 11.5 4.47413 11.4077 4.19985 11.1308L1 7.9C0.451454 7.34616 0.451454 6.51538 1 5.96154C1.54855 5.40769 2.37137 5.40769 2.91991 5.96154L5.20552 8.26923L8.81678 4.62308L12.428 0.976925C12.9766 0.423079 13.7994 0.423079 14.348 0.976925C14.8965 1.43846 14.8965 2.36154 14.348 2.91538Z"
          fill="#ffffff"
        />
      </svg>
    </span>
  );
}

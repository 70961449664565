import { useMutation } from "@apollo/client";
import { Field, Form, Formik, useField } from "formik";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { LoadingIcon } from "../../../components/Icon/Icon";
import RateRecipeMutation from "../../../graphql/RateRecipe.graphql";
import { StarIcon } from "../../../icons/StarIcon";
import { useCookingTranslations } from "../useCookingTranslations";
import { sendCookingEvent } from "../utils";

type IFromValues = {
  rating: number;
  comment: string;
};

export function RatingSystem({
  recipeId,
  onRateError,
  onRateSuccess,
}: {
  recipeId?: string;
  onRateError: (e: Error) => void;
  onRateSuccess: () => void;
}) {
  const tt = useCookingTranslations();
  const rating5 = useMemo(() => new Array(5).fill(null), []);
  const [rateRecipe] = useRateItem();

  return (
    <>
      <h3 className="mx-0 my-4 text-lg font-normal text-light-grey text-center">{tt.howDidYouLikeTheRecipe}</h3>
      <Formik
        initialValues={{ rating: 5, comment: "" }}
        onSubmit={(values: IFromValues) => {
          const { rating } = values;

          sendCookingEvent("rating_submit", { recipeId, rating });

          rateRecipe({
            variables: {
              item: recipeId!,
              rating,
            },
          })
            .then(onRateSuccess)
            .catch(onRateError);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col w-full">
            <div className="flex flex-col w-full">
              <ul className="list-none flex justify-between gap-2 w-full p-0">
                {rating5.map((_, idx) => (
                  <li key={idx} className="cursor-pointer flex justify-center">
                    <Field name="rating" component={StarField} ratingValue={idx + 1} />
                  </li>
                ))}
              </ul>
              <div className="w-full flex justify-between text-base font-normal text-light-grey">
                <span>{tt.poor}</span>
                <span>{tt.great}</span>
              </div>
              {/* <Field
              component='textarea'
              className='my-12 w-full rounded-xl py-4 px-6'
              placeholder='Leave a comment'
              name='comment'
            ></Field> */}
            </div>
            <div className="flex justify-center mt-8">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`${
                  isSubmitting ? "bg-grey" : "bg-dark-green"
                } text-white rounded-full px-16 py-3 font-medium flex items-center relative`}
              >
                {isSubmitting ? tt.sending : tt.rate}
                {isSubmitting && <LoadingIcon className="w-5 h-5 animate-spin absolute right-4" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

function StarField({ ratingValue }: { ratingValue: number }) {
  const [field, , { setValue }] = useField("rating");

  const handleChange = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation();
      setValue(ratingValue);
    },
    [setValue, ratingValue]
  );

  return (
    <StarIcon
      name="rating"
      onClick={handleChange}
      width={42}
      height={42}
      color={field.value >= ratingValue ? "#F8D960" : "#999"}
    />
  );
}

const useRateItem = () =>
  useMutation<
    {},
    {
      item: string;
      rating: number;
    }
  >(RateRecipeMutation);

import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { assign, createMachine, DoneInvokeEvent, MachineConfig } from "xstate";
import { GET_RECIPE_BY_SLUG } from "./services";
import { CookingMachineContext, CookingMachineEvents, GetRecipeBySlugEvent, ICookingRecipe } from "./types";

const machineConfig: MachineConfig<CookingMachineContext, any, CookingMachineEvents> = {
  id: "cooking",
  initial: "idle",
  context: {
    recipe: undefined as unknown as ICookingRecipe,
  },
  on: {
    SET_RECIPE: {
      actions: "setRecipe",
    },
  },
  states: {
    idle: {
      on: {
        GET_RECIPE_BY_SLUG: "loadRecipe",
      },
    },
    loadRecipe: {
      invoke: {
        src: "loadRecipe",
        onDone: {
          target: "recipeLoaded",
          actions: ["setRecipe"],
        },
        onError: {
          target: "recipeNotFound",
          actions: ["setRecipeNull"],
        },
      },
    },
    recipeLoaded: {
      type: "final",
    },
    recipeNotFound: {
      type: "final",
    },
  },
};

export const buildMachine = (client: ApolloClient<NormalizedCacheObject>) =>
  createMachine<CookingMachineContext, CookingMachineEvents>(machineConfig, {
    actions: {
      setRecipe: assign({
        recipe: (_, event) => (event as DoneInvokeEvent<{ recipeBySlug: ICookingRecipe }>).data.recipeBySlug,
      }),
      setRecipeNull: assign({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        recipe: (_) => null,
      }),
    },
    services: {
      loadRecipe: (_, event: CookingMachineEvents) =>
        client
          .query<{ recipe: ICookingRecipe }>({
            query: GET_RECIPE_BY_SLUG,
            variables: {
              slug: (event as GetRecipeBySlugEvent).recipeSlug,
            },
          })
          .then((data) => data.data),
    },
  });

import { RouteComponentProps } from "@reach/router";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "../../../components/Link/Link";
import { HomePage } from "../../../pages";
import { RoundedButton } from "../components/RoundedButton";
import { CookingStateContext } from "../CookingStateProvider";
import { ICookingRecipe } from "../types";
import { useCookingNavigate } from "../useCookingNavigate";
import { useCookingTranslations } from "../useCookingTranslations";

const exists = (recipe?: ICookingRecipe | null) => recipe !== null && typeof recipe !== "undefined";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LoadingStep = (_: React.HTMLProps<HTMLDivElement> & RouteComponentProps) => {
  const navigate = useNavigate();
  const { navigateToWelcome } = useCookingNavigate();
  const { recipe } = useContext(CookingStateContext);
  const tt = useCookingTranslations();
  const handleBack = useCallback(() => navigate({ to: HomePage }), [navigate]);
  const notFound = useMemo(() => recipe === null, [recipe]);

  useEffect(() => {
    if (exists(recipe)) {
      navigateToWelcome();
    }
  }, [navigateToWelcome, recipe]);

  return (
    <div className="flex items-center w-full h-full justify-center bg-turquoise-900 text-white">
      {notFound ? (
        <div className="flex flex-col items-center">
          <h1 className="m-0 text-white font-medium text-3xl">{tt.oops}</h1>
          <p className="my-12 text-white font-medium text-xl">{tt.thisRecipeCannotBeLoaded}</p>
          <RoundedButton text="Go to home" onClick={handleBack} />
        </div>
      ) : (
        <h1 className="m-0 text-white font-medium text-3xl">{tt.loading}</h1>
      )}
    </div>
  );
};

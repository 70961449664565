import { RouteComponentProps, useMatch } from "@reach/router";
import React, { ReactNode, useContext, useEffect } from "react";
import { CookingStateContext } from "./CookingStateProvider";

export function CookingRecipe(props: RouteComponentProps & { children?: ReactNode }) {
  const { getRecipeBySlug } = useContext(CookingStateContext);

  const match = useMatch("/cooking/:recipeSlug/*");
  const recipeSlug = match?.recipeSlug as string;

  useEffect(() => {
    getRecipeBySlug?.(recipeSlug);
  }, [recipeSlug, getRecipeBySlug]);

  // route components in reach router don't inherit className
  return React.cloneElement(props.children as React.ReactElement, { className: "flex w-full" });
}

import * as React from "react";

export function RoundedButton({
  text,
  onClick,
}: {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <button className="bg-dark-green text-white rounded-full px-16 py-3 font-medium " onClick={onClick}>
      {text}
    </button>
  );
}

import { t } from "@lingui/macro";

export type CookingTranslation = ReturnType<typeof useCookingTranslations>;

export function useCookingTranslations() {
  return {
    cookingPage: t({
      id: "CookingPage.title",
      message: "Cooking — Diet Doctor",
    }),
    loading: t({
      id: "CookingPage.loading",
      message: "Loading...",
    }),
    oops: t({
      id: "CookingPage.oops",
      message: "Oops!",
    }),
    thisRecipeCannotBeLoaded: t({
      id: "CookingPage.thisRecipeCannotBeLoaded",
      message: "This recipe cannot be loaded.",
    }),
    greatJob: t({
      id: "CookingPage.greatJob",
      message: "Great job!",
    }),
    thankYou: t({
      id: "CookingPage.thankYou",
      message: "Thank you!",
    }),
    done: t({
      id: "CookingPage.done",
      message: "Done",
    }),
    somethingWentWrongOnOurEnd: t({
      id: "CookingPage.somethingWentWrongOnOurEnd",
      message: "Something went wrong on our end.",
    }),
    tryAgain: t({
      id: "CookingPage.tryAgain",
      message: "Try again",
    }),
    letsGetCooking: t({
      id: "CookingPage.letsGetCooking",
      message: "Let's get cooking!",
    }),
    servings: t({
      id: "CookingPage.servings",
      message: "servings",
    }),
    creator: t({
      id: "CookingPage.creator",
      message: "Creator",
    }),
    tapToNavigate: t({
      id: "CookingPage.tapToNavigate",
      message: "Tap to navigate",
    }),
    back: t({
      id: "CookingPage.back",
      message: "Back",
    }),
    next: t({
      id: "CookingPage.next",
      message: "Next",
    }),
    gotIt: t({
      id: "CookingPage.gotIt",
      message: "Got it",
    }),
    ingredients: t({
      id: "CookingPage.ingredients",
      message: "ingredients",
    }),
    sending: t({
      id: "CookingPage.sending",
      message: "Sending...",
    }),
    rate: t({
      id: "CookingPage.rate",
      message: "Rate",
    }),
    poor: t({
      id: "CookingPage.poor",
      message: "Poor",
    }),
    great: t({
      id: "CookingPage.great",
      message: "Great",
    }),
    howDidYouLikeTheRecipe: t({
      id: "CookingPage.howDidYouLikeTheRecipe",
      message: "How did you like the recipe?",
    }),
  };
}

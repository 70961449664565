import { RouteComponentProps } from "@reach/router";
import React, { useMemo } from "react";
import ReactPlayer from "react-player";
import { ICookingVideo } from "../types";
import { useCookingRecipe } from "../useCookingRecipe";

const youtubeUrl = (id: string) => `https://www.youtube.com/watch?v=${id}`;
const vimeoUrl = (id: string) => `https://player.vimeo.com/video/${id}`;

const videoUrlOrNull = (video: ICookingVideo | null) =>
  video ? (video?.type === "YOUTUBE" ? youtubeUrl(video?.id) : vimeoUrl(video?.id)) : null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const VideoStep = (_: React.HTMLProps<HTMLDivElement> & RouteComponentProps) => {
  const { video } = useCookingRecipe();
  const url = useMemo(() => videoUrlOrNull(video), [video]);

  const { clientWidth, clientHeight } = useMemo(() => document.documentElement, []);

  return url ? <ReactPlayer width={clientWidth} height={clientHeight} playing={true} url={url} /> : null;
};
